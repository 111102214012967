// Img URL
export const logoImgSourceURL = require("../../images/header_logo.png"); //Logo Image URL
export const sliderPosterImgSourceURL = require("../../images/slider_poster.png");
export const sliderBGSourceURL = require("../../images/slider_poster.png");
export const serviceSourceURL = require("../../images/services.png");
export const icon1SourceURL = require("../../images/feature/icon1.png");
export const icon2SourceURL = require("../../images/feature/icon2.png");
export const icon3SourceURL = require("../../images/feature/icon3.png");
export const icon4SourceURL = require("../../images/feature/icon4.png");
export const icon5SourceURL = require("../../images/feature/icon5.png");
export const icon6SourceURL = require("../../images/feature/icon6.png");
export const icon7SourceURL = require("../../images/feature/icon7.png");
export const icon8SourceURL = require("../../images/feature/icon8.png");
export const theSamSoftIconSourceURL = require("../../images/thesamsoft_icon.png");
export const happyClientSourceURL = require("../../images/happy_client.png");
export const contactImgSourceURL = require("../../images/home-contact/contact_img.png");
export const client1SourceURL = require("../../images/client/client1.png");
export const client2SourceURL = require("../../images/client/client2.jpeg");
export const client3pngSourceURL = require("../../images/client/client3.png");
export const client3SourceURL = require("../../images/client/client3.jpeg");
export const client4pngSourceURL = require("../../images/client/client4.png");
export const client4SourceURL = require("../../images/client/client4.jpeg");
export const pgLangBgSourceURL = require("../../images/pg_lang_bg.svg");
export const footerLogoSourceURL = require("../../images/footer_logo.png");
export const webDevelopmentSourceURL = require("../../images/services/web-development.png");
export const appDevelopmentSourceURL = require("../../images/services/app-development.png");
export const webDesigningSourceURL = require("../../images/services/web-designing.png");
export const businessGrowthSourceURL = require("../../images/services/business-growth.png");
export const digitalMarketingSourceURL = require("../../images/services/digital-marketing.png");
export const graphicDesignSourceURL = require("../../images/services/graphic-design.png");
export const hostingSupportSourceURL = require("../../images/services/hosting-support.png");
export const seoSourceURL = require("../../images/services/seo.png");
export const technicalSupportSourceURL = require("../../images/services/technical-support.png");
export const websiteMaintenanceSourceURL = require("../../images/services/website-maintenance.png");
export const errorSourceURL = require("../../images/error.png");
export const aboutUsMainSourceURL = require("../../images/about-us/about-main.png");
export const aboutUsResponsiveIconSourceURL = require("../../images/about-us/responsive-icon.png");
export const aboutUsSupportIconSourceURL = require("../../images/about-us/support-icon.png");
export const aboutUsQualityIconSourceURL = require("../../images/about-us/quality-icon.png");
export const aboutUsDigitalIconSourceURL = require("../../images/about-us/digital-icon.png");
export const aboutUsVissionIconSourceURL = require("../../images/about-us/vission-icon.png");
export const aboutUsMissionIconSourceURL = require("../../images/about-us/mission-icon.png");
export const aboutUsGoalsIconSourceURL = require("../../images/about-us/goals-icon.png");
export const aboutUsMissionBgSourceURL = require("../../images/about-us/mission_bg.png");
export const aboutUsIcon1SourceURL = require("../../images/about-us/team/icon1.png");
export const aboutUsIcon2SourceURL = require("../../images/about-us/team/icon2.png");
export const aboutUsIcon3SourceURL = require("../../images/about-us/team/icon3.png");
export const aboutUsIcon4SourceURL = require("../../images/about-us/team/icon4.png");
export const aboutUsIcon5SourceURL = require("../../images/about-us/team/icon5.png");
export const aboutUsIcon6SourceURL = require("../../images/about-us/team/icon6.png");
export const serviceWhyUsSourceURL = require("../../images/services/why-us.png");
export const serviceNewsLetterSourceURL = require("../../images/services/newsletter.png");
