import React from "react";
import { Link, useParams } from "react-router-dom";
import ImgTag from "../../components/Elements/ImgTag";
import {
  aboutUsDigitalIconSourceURL,
  aboutUsGoalsIconSourceURL,
  aboutUsIcon1SourceURL,
  aboutUsIcon2SourceURL,
  aboutUsIcon3SourceURL,
  aboutUsIcon4SourceURL,
  aboutUsIcon5SourceURL,
  aboutUsIcon6SourceURL,
  aboutUsMainSourceURL,
  aboutUsMissionBgSourceURL,
  aboutUsMissionIconSourceURL,
  aboutUsQualityIconSourceURL,
  aboutUsResponsiveIconSourceURL,
  aboutUsSupportIconSourceURL,
  aboutUsVissionIconSourceURL,
} from "../../assets/js/Constants/localImageURL";
import { t } from "i18next";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

function AboutUsPage() {
  const { lang } = useParams();
  return (
    <>
      {/* Breadcrumb Section Start */}
      <Breadcrumb title={t("About_Us")} />
      {/* Breadcrumb Section End */}
      <section id="about-page_one" className="about-page_one">
        <div className="about">
          <div className="container">
            <div className="row about_row">
              <div className="col-lg-6 col-md-12 img_col">
                <div className="about_img">
                  <ImgTag sourceURL={aboutUsMainSourceURL} altText="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 content_col">
                <div className="about_content">
                  <div className="about_heading">
                    <h2 className="title">
                      {t("Gear_Up_for_Success_with_Premier_Digital_Solutions")}
                    </h2>
                  </div>
                  <p className="text">
                    {t(
                      "TheSamSoft_is_a_dynamic_firm_specializing_in_web_development_web_design_we_ensure_your_business_stands_out_in_the_digital landscape"
                    )}
                  </p>
                  <p className="text skills_text">
                    {t(
                      "At_Thesamsoft_we_utilize_advanced_technologies__web_and_mobile_platforms"
                    )}
                  </p>
                  <div className="contact_btn">
                    <Link to={`/${lang}/contact-us`}>{t("Contact_Us")}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Us Page One Section End */}
      <section id="about-page_service" className="about-page_service">
        <div className="about_service">
          <div className="container">
            <div className="row about_service_row" lang={lang}>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className="ss_flip_card-inner">
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={aboutUsResponsiveIconSourceURL}
                            altText=""
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Custom_Application")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_title">
                          <h2 className="title">{t("Custom_Application")}</h2>
                        </div>
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Custom_app_design_combines_user_friendly_interfaces_responsive_layouts__business_needs"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={1}>
                  <div className="ss_flip_card-inner">
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={aboutUsSupportIconSourceURL}
                            altText=""
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("24_7_Online_Support")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_title">
                          <h2 className="title">{t("24_7_Online_Support")}</h2>
                        </div>
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "For_short_term_projects_or_ongoing_support__miss_a_deadline"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={2}>
                  <div className="ss_flip_card-inner">
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={aboutUsQualityIconSourceURL}
                            altText=""
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Quality_Product")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_title">
                          <h2 className="title">{t("Quality_Product")}</h2>
                        </div>
                        <div className="ss_text">
                          <p className="text">
                            {t("Our_experience_design_arm__connect_the_dots")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={3}>
                  <div className="ss_flip_card-inner">
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={aboutUsDigitalIconSourceURL}
                            altText=""
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Digital_Marketing")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_title">
                          <h2 className="title">{t("Digital_Marketing")}</h2>
                        </div>
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "The_highlight_of_our_collaboration__accessible_to_everyone"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Us Page Service Section End */}
      <section id="ss_mission_sec" className="ss_mission_sec">
        <div className="mission">
          <div className="container">
            <div className="mission_content row">
              <div className="mission_col col-lg-6 col-md-6 col-sm-12">
                <ul className="mission_row">
                  <li className="icon">
                    <ImgTag
                      sourceURL={aboutUsVissionIconSourceURL}
                      altText=""
                    />
                  </li>
                  <li className="content">
                    <h3 className="title">{t("Our_Vission")}</h3>
                    <p className="text">
                      {t(
                        "Empowering_innovation_and_creativity_to_shape_the_future_of_digital_experiences"
                      )}
                    </p>
                  </li>
                </ul>
                <ul className="mission_row">
                  <li className="icon">
                    <ImgTag
                      sourceURL={aboutUsMissionIconSourceURL}
                      altText=""
                    />
                  </li>
                  <li className="content">
                    <h3 className="title">{t("Our_Mission")}</h3>
                    <p className="text">
                      {t(
                        "Delivering_exceptional_digital_solutions_that_drive_success_and_exceed_expectations"
                      )}
                    </p>
                  </li>
                </ul>
                <ul className="mission_row">
                  <li className="icon">
                    <ImgTag sourceURL={aboutUsGoalsIconSourceURL} altText="" />
                  </li>
                  <li className="content">
                    <h3 className="title">{t("Our_Goals")}</h3>
                    <p className="text">
                      {t(
                        "To_achieve_excellence_through_innovative_solutions_and_unparalleled_client_satisfaction"
                      )}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="mission_bg_part col-lg-6 col-md-6 col-sm-12">
                <div className="bg_img">
                  <ImgTag sourceURL={aboutUsMissionBgSourceURL} altText="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Us Page Our Mission Section End */}
      <section id="ss_about_contact_sec" className="ss_about_contact_sec">
        <div className="about_contact">
          <div className="container">
            <div className="content">
              <div className="title">
                <h2>{t("For_additional_information_requests")}</h2>
              </div>
              <div className="contact_btn">
                <Link to={`/${lang}/contact-us`}>{t("Contact_Now")}</Link>
              </div>
            </div>
          </div>
          <div className="about_team">
            <div className="ss-team_member">
              <div className="member">
                <ImgTag sourceURL={aboutUsIcon1SourceURL} altText="" />
              </div>
              <div className="member">
                <ImgTag sourceURL={aboutUsIcon2SourceURL} altText="" />
              </div>
              <div className="member">
                <ImgTag sourceURL={aboutUsIcon3SourceURL} altText="" />
              </div>
              <div className="member">
                <ImgTag sourceURL={aboutUsIcon4SourceURL} altText="" />
              </div>
              <div className="member">
                <ImgTag sourceURL={aboutUsIcon5SourceURL} altText="" />
              </div>
              <div className="member">
                <ImgTag sourceURL={aboutUsIcon6SourceURL} altText="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Us Page Our Mission Section End */}
    </>
  );
}

export default AboutUsPage;
