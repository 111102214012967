export const BREAKPOINTS = { mobile: 0, tablet: 767, desktop: 990.98 };
export const MOBILENUMBER = "+91 7410504432";
export const OFFICIALEMAILID = "official@thesamsoft.com";
export const OFFICIALCOMPANYNAME = "TheSamSoft LLP.";
export const servicesList = [
  { url: "services/web-development", serviceName: "Web Development" },
  { url: "services/app-development", serviceName: "App Development" },
  { url: "services/web-designing", serviceName: "Web Designing" },
  { url: "services/graphic-designing", serviceName: "Graphic Designing" },
  { url: "services/technical-support", serviceName: "Technical Support" },
  { url: "services/website-maintenance", serviceName: "Website Maintenance" },
  { url: "services/hosting-support", serviceName: "Hosting Support" },
  { url: "services/seo", serviceName: "SEO" },
  { url: "services/digital-marketing", serviceName: "Digital Marketing" },
  { url: "services/business-growth", serviceName: "Business Growth" },
];
