import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SpinnerLoader from "../../components/Common/SpinnerLoader";
import axios from "axios";

function UnsubscribePage() {
  const { uniqueId } = useParams();
  console.log("uniqueId", uniqueId);
  const [loading, setLoading] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [message, setMessage] = useState("");
  const handleUnsubscribeFun = async (e) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/emailUnsubscribe`,
        {
          uniqueId,
        }
      );
      if (response.data.message) {
        setLoading(false);
        if (
          response.data.message === "Unique ID is required" ||
          response.data.message === "Record not found"
        ) {
          setUnsubscribed(false);
          setMessage(response.data.message);
        } else {
          setUnsubscribed(true);
          setMessage("");
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error posting data:", error);
    }
  };

  useEffect(() => {
    handleUnsubscribeFun();
  }, [unsubscribed]);

  return (
    <SpinnerLoader loading={loading}>
      <div className="text-center py-5">
        {!unsubscribed ? (
          <h1>{message}</h1>
        ) : (
          <>
            <h1>You Have Been Unsubscribed.</h1>
            <h5>You have been unsubscribed from emails.</h5>
          </>
        )}
      </div>
    </SpinnerLoader>
  );
}

export default UnsubscribePage;
